:root{
	--sidebarWidth: min(95%,230px);
}

.mobile{
	--sidebarWidth: min(95%,250px)
}

.Sidebar{
	width: var(--sidebarWidth);
	border-right: 1px solid var(--borderColor);
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--navColor);
	color: var(--navTextColor);
	background-color: var(--navColor);
	position: sticky;
	top: 0;
}

.mobile .Sidebar{
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
}

.Sidebar h1{
	text-align: center;
	margin: 0;
	padding: 10px;
	height: 60px;
	height: var(--navBarHeight);
	color: var(--neutralAccentColor);
}

.mobile .Sidebar h1{
	font-size: 20pt;
	text-align: left;
	padding-left: 25px;
	position: relative;
}

.mobile .Sidebar h1 .closeButton{
	position: absolute;
	right: 15px;
}

.Sidebar .SideButton{
	/* border-top: 1px solid var(--borderColor); */
	padding: 3px 10px;
	overflow: hidden;
	background-color: var(--navColor);
	cursor: pointer;
}

.Sidebar .SideButton a{
	text-decoration: none !important;
}

.Sidebar .SideButton .textBox{
	border-radius: var(--smallRadius);
	padding: 8px 15px;
	background-color: var(--navColor);
	-webkit-user-select: none;
	user-select: none;
}

.Sidebar .SideButton .textBox:hover{
	color: var(--neutralAccentColor);
	background-color: rgb(var(--neutralAccentValues), 0.06);
}

.Sidebar .SideButton.active .textBox{
	color: var(--neutralAccentColor);

	background-color: rgb(var(--neutralAccentValues), 0.06);
	font-weight: 600;
}

.Sidebar .SideButton .textBox:hover:active{
	filter: brightness(90%);
	background-color: rgb(var(--neutralAccentValues), 0.08);
}

.NotSidebar{
	width: calc(100% - var(--sidebarWidth));
	height: 100vh;
	overflow-y: auto;
}

.mobile .NotSidebar{
	width: 100vw;
}
