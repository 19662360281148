.InvoiceTable .StatusCell{
	border-radius: 20px;
	color: white;
	padding: 0px 10px;
}

.InvoiceTable .StatusCell .text{
	display: inline-block;
	transform: translateY(-1px);
}
