:root{
	--navBarHeight: 55px;
}

.NavBar{
	padding: 0 20px;
	position: sticky;
	top: 0;
	border-bottom: 1px solid var(--borderColor);
	overflow: hidden;
	z-index: 10;
	background-color: var(--bodyBG);
}

.NavBar .navContainer{
	background-color: var(--bodyBG);
	color: var(--mainTextColor);
	height: var(--navBarHeight);
	flex-wrap: wrap;
}

.NavBar .childrenBox{
	padding: 0 10px;
	display: inline-flex;
	gap: 15px;
}

.mobile .NavBar .childrenBox button.button{
	font-size: 11pt;
}

.NavBar .titleBox{
	color: var(--neutralAccentColor);
	font-weight: 550;
	padding: 10px;
	padding-left: 0px;
	font-size: 18pt;
}

.mobile .NavBar .titleBox{
	font-size: 14pt;
}

.mobile .NavBar .titleBox .hamburger{
	padding-right: 10px;
}

.NavBar .controls{
	position: absolute;
	right: 0;
	padding: 10px;
}

.NavBar .controls .icon{
	color: var(--neutralAccentColor);
	font-size: 22pt;
}

.mobile .NavBar .controls .icon{
	font-size: 18pt;
}

.NavBar .button{
	padding: 8px 15px;
}


