.ChargeTable{
	padding: 10px 15px;
}

.ChargeTable .DeleteButton,
.ChargeTable .cellButton{
	color: var(--lightTextColor);
}

.ChargeTable .noCharges{
	text-align: center;
	padding: 100px 0;
	color: var(--lightTextColor);
}
