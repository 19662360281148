.Dashboard section{
	padding: 10px;
}

.Dashboard section {
	border-bottom: 1px solid var(--borderColor);
}

.Dashboard .space{
	padding-bottom: 5px;
	padding-left: 5px;
}

.Dashboard .refreshAccountValue{
	font-size: 11pt;
	color: gray;
	padding-left: 10px;
}
