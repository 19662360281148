.TabHeaders{
	padding: 10px
}

.TabHeaders .tabHeaderBox{
	padding: 10px 15px;
	cursor: pointer;
	font-weight: bold;
}

.TabHeaders .tabHeaderBox:hover{
	color: var(--neutralAccentColor);
}

.TabHeaders .tabHeaderBox.activeTab{
	text-decoration: underline;
	color: var(--neutralAccentColor);
}
