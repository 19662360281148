.CreateInvoicePopup .story{
	padding: 10px 0;
}

.CreateInvoicePopup .chargeCodeRow{
	padding: 10px 0;
}

.CreateInvoicePopup .info{
	padding-left: 10px;
}

.CreateInvoicePopup .row{
	padding: 10px 0;
}

.CreateInvoicePopup .message:empty{
	display: none;
}

.CreateInvoicePopup .message{
	color: red;
	padding: 10px
}

.CreateInvoicePopup .space{
	padding: 0 30px;
}
