.FilterBar {
	padding: 10px;
	padding-right: 30px;
	white-space: nowrap;
	width: 100%;
	overflow-x: auto;
}

.FilterBar .filterSection {
	margin-right: 40px;
	width: 300px;
	display: inline-block;
}

.FilterBar .filterSection label {
	margin-right: 15px;
	display: block;
	padding-bottom: 5px;
	color: var(--lightTextColor);
	font-weight: bold;
}

.FilterBar .clearButton {
	color: var(--lightTextColor);
}
